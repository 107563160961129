html,
body {
  width: 100vw;
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden !important;
  overflow-y: overlay;
}

body::-webkit-scrollbar {
  width: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.button-wrap {
  border-radius: 1.625rem;
  border: solid 1px #ffffff;
  background-color: transparent;
  color: #ffffff;
  font-size: 1rem;
}

.button-wrap + .button-wrap {
  margin-left: 2rem;
}

.button-medium-size {
  width: 11.25rem;
  height: 2.625rem;
  border-radius: 1.625rem;
  border: solid 1px #ffffff;
}

.slick-dots {
  display: none !important;
}
